<template>
  <div class="conpontent-wrapper" id="sort-chart">
    <div id="title">
      <span style="font-weight: bolder; color: white;">{{ data.rankName }}</span>
      <!-- <span>{{ rankKind }}排名</span> -->
    </div>
    <div ref="sortChart" id="chart"></div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import { TooltipComponent, GridComponent } from "echarts/components";
import { PictorialBarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([
  TooltipComponent,
  GridComponent,
  PictorialBarChart,
  CanvasRenderer,
]);
export default {
  name: "SortChart",
  data() {
    return {
      option: {
        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: "linear",
        animationEasingUpdate: "linear",
        dataset: [
          {
            dimensions: ["project", "total", "rate"],
            source: [],
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          formatter: function (params) {
            return params[0].name + ": " + params[0].value;
          },
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          splitLine: false,
          axisLabel:false
        },
        yAxis: {
          type: "category",
          axisLabel: {
            textStyle: {
              color: "#3fcccf", //
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        series: [
          {
            datasetIndex: 0,
            encode: {
              x: "total",
              y: "project",
            },
            type: "bar",
            label: {
              show: true,
              position: ['40%', '45%'],
              color: "#e54035",
              valueAnimation: true,
            },
            itemStyle: {
              color: function (params) {
                const colors = [
                  "#4169E1",
                  "#FFFF00",
                  "#7CFC00",
                  "#FFB5C5",
                  "#FFA500",
                  "#4169E1",
                  "#FFFF00",
                  "#7CFC00",
                  "#FFB5C5",
                  "#FFA500", 
                  "#4169E1",
                  "#FFFF00",
                  "#7CFC00",
                  "#FFB5C5",
                  "#FFA500",
                  "#0000FF",
                  "#EEEE00",
                  "#00FF00",
                  "#EEA9B8",
                  "#EE9A00",
                ];

                return colors[params.dataIndex];
              },
            },
          },
        ],
      },
    };
  },
  props: {
    data: {
      type: Object,
      default: {
        rankName: "",
        rankCol: [],
        rankData: [],
      },
    },
    theme: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#e54035",
    },
  },
  computed: {
    mychart() {
      return echarts.init(this.$refs.sortChart);
    },
    // rankKind() {
    //   if (this.option.series[0].encode.y == "total") return "总数";
    //   else return "合格率";
    // },
    myData() {
      let mydata = [];
      let col = this.data.rankCol;
      let data = this.data.rankData.slice(0, 20);
      if (col.length !== data.length)
        console.log(new Error("dif length bt rank item & data"));
      for (let i in data) {
        let rate = 0;
        let total = data[i]["qualified"] + data[i]["unqualified"];
        if (total !== 0)
          rate = Math.round((10000 * data[i]["qualified"]) / total) / 100;
        mydata.push([col[i], total, rate]);
      }
      console.log(mydata);
      return mydata;
    },
  },
  watch: {
    data: function () {
      this.prepare();
    },
  },
  methods: {
    reSizeChart: function () {
      this.mychart.resize();
    },
    prepare() {
      let mydata = this.myData;
      mydata.sort((a, b) => {
        return a[1] - b[1];
      });
      if (mydata) this.option.dataset[0].source = mydata;
      else console.log(new Error("Error in data"));
      this.mychart && this.mychart.setOption(this.option);
      this.mychart.getZr().on("click", (e) => {
        if (e.target) return;
        let cur = this.option.series[0].encode.x;
        console.log(cur);
        if (cur === "total") {
          this.option.series[0].encode.x = "rate";
          let data = this.option.dataset[0].source;
          data.sort((a, b) => {
            return a[2] - b[2];
          });
        } else {
          this.option.series[0].encode.x = "total";
          this.option.dataset[0].source.sort((a, b) => {
            return a[1] - b[1];
          });
        }
        this.mychart.setOption(this.option);
      });
    },
  },
  mounted() {
    const resizeObserver = new ResizeObserver(this.reSizeChart);
    resizeObserver.observe(this.$el);
  },
};
</script>

<style lang="less" scoped>
.conpontent-wrapper {
  width: 35vh;
  height: 78vh;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--el-box-shadow-base);
  #title {
    width: 100%;
    flex: 0 0 auto;padding:1vh 0;
  }
  #chart {
    width: 100%;
    flex: 1 1 0;
  }
}
#sort-chart {
  color: white;
  // background-color: white;
}
</style>