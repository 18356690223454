<template>
  <div id="pie-chart" ref="chart"></div>
</template>
<script>
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  LegendComponent,
  TransformComponent,
} from "echarts/components";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import "echarts/theme/macarons.js";
import "echarts/theme/blue.js";
import "echarts/theme/green.js";
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  PieChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
]);
export default {
  name: "PieChart",
  computed: {
    mychart() {
      return echarts.init(this.$refs.chart,this.theme);
    },
  },
  props: {
    data: {
      type: Object,
      default:{
        total:0,
        rate: [ 
        { value: 0, name: "合格率" },
        { value: 0 , name: "不合格率" }
        ]
      },
    },
    theme: {
      type: String,
      default: "",
    },
  },
  watch: {
    data: function () {
      this.option.series[0].data = this.data.rate;
      this.mychart && this.mychart.setOption(this.option);
    },
  },
  methods: {
    reSizeChart:function(){
      this.mychart.resize();
    }
  },

  mounted() {
    this.mychart && this.mychart.setOption(this.option);
    const resizeObserver = new ResizeObserver(
      this.reSizeChart
    );
    resizeObserver.observe(this.$el);
  },
  data() {
    return {
      option: {
        // legend: { bottom: 0 },
        dataset: {
          source: [],
        },
        series: [
          
          {
            type: "pie",
            id: "pie",
            radius: ["75%", "65%"],
            label: {
              show: true,
              // position: "center",
              fontSize: "8",
                // fontWeight: "bold",
                formatter: '{b} :{d}%',
                textShadowColor:'transparent'
            },
            data: this.data.rate,
            itemStyle: {
            color: function(params) {
                var colorList = ['#61b22f','#fac700']; // 自定义颜色列表
                return colorList[params.dataIndex]; // 根据索引返回对应位置的颜色值
            }
        },
          },
        ],
      },
    };
  },
};
</script>
<style>
  #pie-chart{
    width:20vh;
    height: 20vh;
  }
</style>