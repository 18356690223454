<template>
  <div id="scatter-chart" ref="chart"></div>
</template>
<script>
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  LegendComponent,
  TransformComponent,
} from "echarts/components";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import "echarts/theme/macarons.js";
import "echarts/theme/blue.js";
import "echarts/theme/green.js";
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  PieChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
]);
export default {
  name: "PieChart",
  computed: {
    mychart() {
      return echarts.init(this.$refs.chart, this.theme);
    },
  },
  props: {
    data: {
      type: Array,
      default: [0, 0, 0, 0],
    },
    theme: {
      type: String,
      default: "",
    },
  },
  watch: {
    data: function () {
      const groups = this.pointCreater()
      console.log('groups',groups);
      this.option.series.forEach((item, index) => {
        item.data = groups[index];
      });
      this.mychart && this.mychart.setOption(this.option);
    },
  },
  methods: {
    reSizeChart: function () {
      this.mychart.resize();
    },
    pointCreater:function(){
      

      const ttpoints = []
      for(let i = 10;i<=100;i+=10){
        for(let j = 10;j<=100;j+=10){
          const x = Math.round(Math.random()*10)
          const y = Math.round(Math.random()*10)
          ttpoints.push([i-x,j-y])
        }
      }
      const tdpoints = []
      for(let i = 10;i<=100;i+=10){
        for(let j = 10;j<=100;j+=10){
          const x = Math.round(Math.random()*10)
          const y = Math.round(Math.random()*10)
          tdpoints.push([i-x,j-y])
        }
      }
      //  points.sort((a,b)=>{
      //   return (a[0]+a[1]) - (b[0]+b[1])
      //  })


      //不合格组
      const ttmin = Math.floor(this.data[1])
      const ttminGroup = ttpoints.slice(0,ttmin)
      const ttmaxGroup = ttpoints.slice(ttmin,100)

      const tdmin = Math.floor(this.data[3])
      const tdminGroup = tdpoints.slice(0,tdmin)
      const tdmaxGroup = tdpoints.slice(tdmin,100)

      return [ttmaxGroup,ttminGroup,tdmaxGroup,tdminGroup]
    }
  },

  mounted() {
    this.mychart && this.mychart.setOption(this.option);
    const resizeObserver = new ResizeObserver(this.reSizeChart);
    resizeObserver.observe(this.$el);
  },
  data() {
    return {
      option: {
        grid:{
          show:false
        },
        xAxis: {
          axisLabel:false,
          axisTick:false,
          splitLine:false
        },
        yAxis: {
          axisLabel:false,
          axisTick:false,
          splitLine:false
        },
        legend: {
          top: 0,
          data: [
            "总检测合格率",
            "总检测不合格率",
            "今日检测合格率",
            "今日检测不合格率",
          ],
          textStyle: {
            fontSize: 10,
            color: "white",
          },
        },
        series: [
          { symbolSize: 6,
            name: "总检测合格率",
            data: [0],
            type: "scatter",
            id: "t0",
            itemStyle: {
              color: "#00CD00",
            },
          },
          { symbolSize: 6,
            name: "总检测不合格率",
            data: [0],
            type: "scatter",
            id: "t2",
            itemStyle: {
              color: "#FF0000",
            },
          },
          { symbolSize: 6,
            name: "今日检测合格率",
            data: [0],
            type: "scatter",
            id: "t1",
            itemStyle: {
              color: "#00FF00",
            },
          },

          { symbolSize: 6,
            name: "今日检测不合格率",
            data: [0],
            type: "scatter",
            id: "t3",
            itemStyle: {
              color: "#FF4500",
            },
          },
        ],
      },
    };
  },
};
</script>
<style>
#scatter-chart {
  width: 40vh;
  height: 30vh;
}
</style>