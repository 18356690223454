<template>
  <div id="home-root" style="height: 100%">
    <!-- <div id="home-root" style="height: 100%; width: 100%"> -->
    <div class="welcome-board">
      <div class="welcome-board-left">
        <div v-loading="loading" class="scatter-box">
          <div class="rates">
            <p>总检测合格率:{{ this.totalRate }}%</p>
            <p>今日检测合格率:{{ this.todayRate }}%</p>
          </div>
          <scatter-chart :data='scatterData' />
        </div>
        <div class="data-profile-board">
            <div style="line-height: 4vh; height: 4vh; color: #66ffff;text-align:center">
              <span style="font-size: 2vh; height: 2vh; font-weight: bold"
                >今日数据</span
              >
            </div>
            <div class="table-box">
              <el-table
                style="
                  --el-table-border-color: none;
                  --el-table-row-hover-bg-color: #0d6c9f;
                "
                class="transparent-table"
                :border="false"
                :data="SnapshotList"
                :show-header="false"
                height="38vh"
                :row-style="rowStyle"
                :header-row-style="{
                  'font-size': '1.5vh',
                  height: '2vh',
                  'line-height': '2vh',
                  color: 'black',
                  border: '1px solid black',
                }"
                v-loading="loading"
                :cell-style="redCell"
              >
                <el-table-column prop="company_name" label="被检单位" />
                <el-table-column prop="sample" label="样品" />
                <el-table-column prop="check_item" label="检测项目" />
                <el-table-column
                  prop="check_result"
                  label="结果"
                  :formatter="
                    (row, column) => {
                      let result = row[column.property];
                      switch (result) {
                        case '1':
                          return '合格';
                        case '2':
                          return '不合格';
                        default:
                          return '待定';
                      }
                    }
                  "
                />
              </el-table>
            </div>
          </div>
      </div>
 <div class="decorate">  
  <div class="top-title">
    <div class="numbers">
      <p>{{this.totalNum}}</p>
      <p>{{this.todayNumber}}</p>
    </div>
    <div class="texts"><p>总检测数</p><p>今日检测数</p></div>
  </div>
            <map-chart></map-chart>
          </div>
      <div class="welcome-board-right">
        <div class="side-top-right">
        
          
        </div>
        <div class="content-wrapper">
          <div class="overview-board">
            <div class="overview-content">
              <el-row v-loading="loading" style="width: 100%; height: 100%;justify-content:center">
                <col-bar-chart :data="currentRank" />
              </el-row>
              <div class="collapse-button">
                <el-button
                  v-if="rank.length > 1"
                  style="width: 5%"
                  type="primary"
                  plain
                  :icon="ArrowRight"
                  @click="showNextRank()"
                >
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SortChart from "component/SortChart.vue";
import colBarChart from 'component/colBarChart.vue'
import MapChart from 'component/MapChart.vue'
import { ArrowLeft, Calendar, ArrowRight } from "@element-plus/icons-vue";
import PieChart from "component/PieChart.vue";
import { getDataList, getAreaData } from "network/checkdata.js";
import {
  getOrgOverview,
  getSubOverview,
  getAreaOverview,
} from "network/overview.js";
import { getAreaInfo } from "network/area.js";
import { getOrgDetail } from "network/org.js";
import ScatterChart from '../../components/scatterChart.vue';

export default {
  name: "Home",
  components: {
    ArrowLeft,
    PieChart,
    SortChart,
    colBarChart,
    MapChart,
    ScatterChart
  },
  methods: {
    rowStyle({ row, rowIndex }) {
      let style = {
        height: "2vh",
        fontSize: "1.5vh",
        color: "#f2f2f2",
        "background-color": "#041469",
        border: "none",
      };
      if (rowIndex % 2 === 0) {
        style["background-color"] = "#051e7c";
      }

      return style;
    },
    showNextRank() {
      let rank = this.rank;
      let i = (this.rankIndex + 1) % rank.length;
      this.currentRank = rank[i];
      this.rankIndex = i;
    },
    init() {
      this.getRankItem();
      this.getSampleOfToday();
    },
    isGov() {
      return this.userRole === "gov";
    },
    isOrg() {
      return this.userRole === "org";
    },

    getRankItem() {
      let auth = this.userAuth;
      let promise = [];
      let endTime = new Date(new Date().toLocaleDateString()).getTime();
      let startTime = endTime - 1000 * 3600 * 24 * 365;
      endTime = Date.now();
      if (!this.isOrg()) {
        for (let item of auth) {
          promise.push(
            getSubOverview(startTime, endTime, item).then(
              (res) => {
                let name = item;
                let col = [];
                let data = [];
                for (let [k, v] of Object.entries(res.data)) {
                  col.push(k);
                  data.push(v);
                }
                res.data = { name, col, data };
                return res;
              },
              (err) => {
                console.log(err.message);
              }
            )
          );
        }
        Promise.all(promise).then(
          (res) => {
            for (let { data } of res) {
              if (data.name.match(/\d{4}00/)) {
                for (let i in data.col)
                  data.col[i] = this.$store.getters["areas/areaName"](
                    data.col[i]
                  );
                data.name = this.$store.getters["areas/areaName"](data.name);
                this.rank.push({
                  rankName: data.name,
                  rankCol: data.col,
                  rankData: data.data,
                });
                this.getOverviewData();
              } else {
                getAreaInfo(data.name).then(
                  (res1) => {
                    data.name = res1.data[0].area_name;
                    this.rank.push({
                      rankName: data.name,
                      rankCol: data.col,
                      rankData: data.data,
                    });
                    this.getOverviewData();
                  },
                  (rej) => {}
                );
              }
            }
          },
          (reason) => {
            console.log(reason);
          }
        );
      } else {
        for (let i in auth) {
          let item = auth[i];
          promise = [];
          promise.push(
            getOrgDetail(item).then(
              (res) => {
                return res.data.org_name;
              },
              (rej) => {
                console.log(rej.message);
              }
            )
          );

          promise.push(
            getOrgOverview(startTime, endTime, [item]).then(
              (res) => {
                let col = [];
                let data = [];
                for (let [k, v] of Object.entries(res.data)) {
                  col.push(k);
                  data.push(v);
                }
                return [col, data];
              },
              (rej) => {
                console.log(rej.message);
              }
            )
          );
          Promise.all(promise).then(
            (res) => {
              this.rank.push({
                rankName: res[0],
                rankCol: res[1][0],
                rankData: res[1][1],
              });
              if (i == auth.length - 1) this.getOverviewData();
            },
            (rej) => {
              console.log(rej);
            }
          );
        }
      }
    },
    getOverviewData() {
      let total = 0;
      let qualified = 0;
      let ranks;
      if (this.rank.length > 0) ranks = this.rank;
      else return;

      for (let rank of ranks) {
        for (let item of rank.rankData) {
          total += item.qualified + item.unqualified;
          qualified += item.qualified;
        }
      }
      this.totalNum = total;
      this.totalqua = qualified
      if (total !== 0)
        this.totalRate = Math.round((qualified / total) * 10000) / 100;
      this.totalData = {
        total,
        rate: [
          { value: this.totalRate, name: "合格率" },
          { value: (100 - this.totalRate).toFixed(2), name: "不合格率" },
        ],
      };
      this.totalunpass = (100 - this.totalRate).toFixed(2)
      console.log('总数',total,this.totalRate);
    },

    getSampleOfToday() {
      let endTime = new Date();
      let startTime = new Date(
        endTime.getTime() - (endTime.getTime() % 86400000) - 28800000
      );
      getDataList([startTime, endTime], null, 0, null, 20).then((res) => {
        this.todayNumber = res.data.total;
        if (res.data.total !== 0)
          this.todayRate =
            Math.round((10000 * res.data.qualified) / res.data.total) / 100;

        this.SnapshotList = res.data.dataList;
        this.todayqua = res.data.qualified
        this.todayData = {
          total: res.data.total,
          rate: [
            { value: this.todayRate, name: "合格率" },
            { value: (100 - this.todayRate).toFixed(2), name: "不合格率" },
          ],
        };
        this.todayunpass = (100 - this.todayRate).toFixed(2)
      });
    },
  },
  created() {
    this.loading = true;
    setTimeout(() => {
      this.init();
      this.loading = false;
    }, 6000);
  },
  data() {
    return {
      ArrowRight,
      oneYear: {
        qualified: 0,
        unqualified: 0,
      },
      totalRate: 0,
      totalunpass:0,
      totalNum: 0,
      todayunpass:0,
      totalqua:0,
      totalunqua:0,
      todayNumber: 0,
      todayqua:0,
      todayRate: 0,
      totalData: {
        total: 0,
        rate: [
          { value: 0, name: "合格率" },
          { value: 0, name: "不合格率" },
        ],
      },
      todayData: {
        total: 0,
        rate: [
          { value: 0, name: "合格率" },
          { value: 0, name: "不合格率" },
        ],
      },
      rank: [],
      rankIndex: 0,
      rankData: {
        rankName: "",
        rankCol: [],
        data: [],
      },
      loading: false,
      SnapshotList: [],
    };
  },
  computed: {
    scatterData(){
      return [this.totalRate,this.totalunpass,this.todayRate,this.todayunpass]
    },
    currentRank() {
      return this.rank[this.rankIndex];
    },
    userAuth() {
      return this.$store.getters.getUserAuth;
    },
    userRole() {
      return this.$store.getters.getUserRole;
    },
    currentTime() {
      let date = new Date();
      return (
        date.getFullYear() +
        "年" +
        (date.getMonth() + 1) +
        "月" +
        date.getDate() +
        "日"
      );
    },
    greetings() {
      let date = new Date();
      return date.getHours() < 12
        ? "上午"
        : date.getHours() < 18
        ? "下午"
        : "晚上";
    },
  },
};
</script>
<style lang="less" scoped>
#home-root {
  position: relative;
  overflow: hidden;
  width: calc(100%-8vh);
  height: 90vh;
  padding: 2vh 5vh 0;
  justify-content: center;
  // padding-right: 2vh;
  // background-color: #071f63;
  
  background-image: url('../../assets/pictures/bg.jpg');
  background-origin: 60% 50%;
}

.welcome-board {
  // position: absolute;
  left: 5vh;
  // width: 30vh;
  // width: calc(100% - 2.5vw);
  // flex: 0 0 50%;
  display: flex;
  flex-direction: row;
  min-height: 200px;
  justify-content: center;
  //background-color: #4996e2;

  .welcome-board-left {
    margin-right: 2vh;
    // padding-left: 5vh;
    // flex: 1 1 40%;
    white-space: nowrap;
    display: flex;
    align-items: left;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;
    color: white;
    height: 78vh;

    .card-wrapper {
      padding-right: 2vw;
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .el-card {
      height: 15vh;
      width: 15vh;
      --el-card-padding: 0;
      border-radius: 1vh;
      text-align: center;
      // background-color: rgba(255, 255, 255, 0.438);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border-color: transparent;

      .title {
        font-size: 2vh;
        white-space: nowrap;
        height: 3vh;
      }

      .content {
        font-size: 2vh;
        height: 3vh;
      }
    }
  }

  .welcome-board-right {
    // flex: 1 1 60%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.data-profile-board {
  width: 50vh;
  background-color: #153469;
  // border: 1px solid #336699;
  flex: 0 0 40%;
  // height: 20vh;
  box-shadow: var(--el-box-shadow-base);
  color: #303133;
  border: 1px solid #0d53b7;
  border-radius: 8px;
}

.content-wrapper {
  // width: calc(100% - 2.5vw);
  // width: 143vh;
  // height: 40vh;
  flex: 0 0 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #0d53b7;
  border-radius: 8px;

  .overview-board {
    // flex: 0 0 calc(60% - 10px);
    // width: 140vh;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.04);
    // border: 1px solid #336699;

    .overview-content {
      flex: 0 0 98%;
      height: 100%;
      width: 40vh;
      justify-content: center;

      position: relative;
      .collapse-button {
        position: absolute;
        top: 50%;
        right: 0px;
        z-index: 10;
      }
    }

    #title {
      font-size: 2vh;
      font-weight: bold;
      color: gray;
      line-height: 4vh;
      white-space: nowrap;
    }
  }
}

.check-group, .scatter-box {
  display: flex;
  width: 50vh;
  color: #303133;
  font-weight: bold;
  text-align: center;
  border: 1px solid #0d53b7;
  border-radius: 2.5%;
  flex-direction:column ;
  justify-content: space-between;
  align-items: center;
  .rates {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    color: #ffeb7b;
    font-size: 1.5vh;
  }
}
.check-total {
  overflow: hidden;
}
.check-today {
  width: 25vh;
  overflow: hidden;
}
.check-info {
  width: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
}
.check-title {
  width: 100%;
  height: 4vh;
  line-height: 4vh;
  margin-bottom: 1vh;
  background-color: #66b3ff;
}
.side-right {
  display: flex;
}
.side-top-right {
}
.decorate {
  width: 80vh;
  height: 80vh;
  margin-right: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  
  background-repeat: no-repeat;
  // border: 1px solid #0d53b7;
  border-radius: 5%;
  .img {
    width: 51.3vh !important;
    height: 37.8vh !important;
  }
  .top-title {
    width: 60vh;
    background: rgba(101,132,226,.1);
    padding: 0.5vh;
    
    .numbers {
      position: relative;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      border: 1px solid rgba(25,186,139,.17);
      color: #ffeb7b;
      font-size: 4vh;
      font-weight: bold;
      &::after{
        position: absolute;
        content: '';
        width: 2vh;
        height: 1vh;
         border-right: 0.3vh solid #02a6b5;
        right: 0;
        bottom: 0;
        border-bottom: 0.3vh solid #02a6b5;
      }
      &::before{
        position: absolute;
        content: '';
        width: 2vh;
        height: 1vh;
        border-left: 0.3vh solid #02a6b5;
        left: 0;
        top: 0;
        border-top: 0.3vh solid #02a6b5;
      }
    }
    .texts {
      display: flex;
      justify-content: space-evenly;
      color: #637c9f;
    }
    .numbers p,.texts p {
      width: 15vh;
      text-align: center;
    }
  }
}
.data-profile-board /deep/ .el-table {
  background-color: none;
}

.table-box /deep/ .el-table__body-wrapper {
  background-color: #071f63;
}
    
</style>