<template>
<div class="container">
  <div id="map-chart" ref="chart"></div></div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  DatasetComponent,
  VisualMapComponent,
  GeoComponent
} from "echarts/components";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { MapChart } from "echarts/charts";
import "echarts/theme/macarons.js";
import "echarts/theme/blue.js";
import "echarts/theme/green.js";
import hubei from 'utils/hubei.js'
echarts.use([
  TitleComponent,
  TooltipComponent,
  DatasetComponent,
  MapChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  ToolboxComponent,
  VisualMapComponent,
  GeoComponent,
]);
export default {
  name: "MapChart",
  computed: {
    mychart() {
      return echarts.init(this.$refs.chart, this.theme);
    },
  },
  watch: {
    data: function () {
      this.mychart && this.mychart.setOption(this.option);
    },
  },

  props: {
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    theme: "",
  },
  data() {
    return {
      option: {
      //   tooltip: {
      //   },
      //   toolbox: {
      //     show: false,
      //     orient: "vertical",
      //     left: "right",
      //     top: "center",
      //     feature: {
      //       dataView: {
      //         readOnly: false,
      //       },
      //       restore: {},
      //       saveAsImage: {},
      //     },
      //   },
        // visualMap: {
        //   min: 0,
        //   max: 5000,
        //   text: ["High", "Low"],
        //   realtime: false,
        //   calculable: true,
        //   inRange: {
        //     color: ["orangered", "yellow", "lightskyblue"],
        //   },
        // },
        
        // series:
        //  [
        //   {
        //     name: "武汉各地区检测分布",
        //     type: "map",
        //     map: "wuhan",
        
        //     layoutCenter: ['50%', '50%'],
        //     layoutSize: '70%',
        //     roam:'move',
        //     data:
            //  [
            //     {
            //         name:'江夏区',
            //         value:1000
            //     },
            //     {
            //         name:'江汉区',
            //         value:25
            //     },
            //     {
            //         name:'洪山区',
            //         value:2500
            //     },
            //     {
            //         name:'新洲区',
            //         value:4999
            //     },
            //     {
            //         name:'武昌区',
            //         value:10
            //     },
            //     {
            //         name:'蔡甸区',
            //         value:1023
            //     },{
            //         name:'黄陂区',
            //         value:3507
            //     },{
            //         name:'东西湖区',
            //         value:24
            //     }
            // ],
            
            // 自定义名称映射
          // },
        // ],
    //   },
    geo: {
                type: "map",
                aspectScale: 1, // 横向拉伸
                // roam: true, // 地图操作 开启缩放或者平移，可以设置成 'scale' 或者 'move'。
                map: "hubei",
                label: {
                    show: true,
                    normal: {
                        show: true, // 默认地图文字字号和字体颜色
                        fontSize: 10,
                        color: "#ffffff",
                    },
                    emphasis: {
                        show: true,
                        fontSize: 10, // 选中地图文字字号和字体颜色
                        color: "#CFCFCF",
                    },
                },
                itemStyle: {
                    normal: {
                        areaColor: "#040c3c", //地图本身的颜色
                        borderColor: "#00feda", //省份边框颜色
                        borderWidth: 1, // 省份边框宽度
                        opacity: 1, //图形透明度
                    },
                    emphasis: {
                        areaColor: "#040c3c", // 高亮时候地图显示的颜色
                        borderWidth: 0, // 高亮时的边框宽度
                    },
                },
                textFixed: {
                Alaska: [20, -20],
                }
            },
            series: [
                {
                type: "effectScatter",
                coordinateSystem: "geo",
                symbolSize: 12,
                label: {
                    normal: {
                    show: false,
                    },
                    emphasis: {
                    show: false,
                    },
                },
                itemStyle: {
                    normal: {
                    shadowBlur: 10,
                    color: "#00ECC8",
                    },
                    emphasis: {
                    borderColor: "#fff",
                    borderWidth: 1,
                    },
                },
                },
            ],}
    };
  },
  methods: {
    resizeChart: function () {
      this.mychart.resize();
    },
    initEcharts() {
        const map = require("../assets/hu2_bei3_wu3_han4.json")
				this.mychart.showLoading();
				//加载地图文件
				this.mychart.hideLoading()
				// echarts.registerMap('wuhan', map)
    echarts.registerMap("hubei",hubei)
				this.mychart.setOption(this.option)
				// 观测更新的数据在 view 层可以直接访问
				this.mychart.setOption(this.option)
			},
  },
  mounted() {
    this.initEcharts()
    const resizeObserver = new ResizeObserver(this.resizeChart);
    resizeObserver.observe(this.$el);

  },
};
</script>
<style lang="less" scoped>
.container{
  // width: 50vh;
  // height: 38vh;

  
  #map-chart {
    width: 70vh;
    height: 38vh;
  }
}
</style>