<template>
  <div class="conpontent-wrapper" id="sort-chart">
    <div id="title">
      <span style="font-weight:bolder;color:white;padding:1vh 0;">{{data.rankName}}</span><span>{{rankKind}}排名</span>
    </div>
    <div ref="sortChart" id="chart"></div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import { TooltipComponent, GridComponent} from "echarts/components";
import { PictorialBarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([
  TooltipComponent,
  GridComponent,
  PictorialBarChart,
  CanvasRenderer,
]);
export default {
  name: "SortChart",
  data() {
    return {
      option: {
        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear',
        dataset:[
          {
            dimensions:['project','total','rate'],
            source:[]
          }
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          formatter: function (params) {
            return params[0].name + ": " + params[0].value;
          },
        },
        xAxis: {
          axisTick: { show: false },
          axisLine: { show: false },
          splitLine:false,
          axisLabel: {
            color: "#e54035",
          },
          animationDuration:300,
          animationDurationUpdate:300,
        },
        yAxis: {
          type: 'category' ,
          splitLine: { show: false },
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: { show: false },
          max:'dataMax'
        },
        color: ['#e54035','#4996e2'],
        series: [
          {
            datasetIndex:0,
            encode:{
              x:'total',
              y:'project'
            },
            label:{show:true,position:'top',color:"#e54035",valueAnimation:true},
            type: "bar",
            barCategoryGap: "-130%",
            // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
            symbol:
              "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
            itemStyle: {
              opacity: 0.5,
            },
            emphasis: {
              itemStyle: {
                opacity: 1,
              },
            },
            z: 10,
          },
        ],
      },
    };
  },
  props: {
    data: {
      type: Object,
      default: {
        rankName:'',
        rankCol:[],
        rankData:[]
      },
    },
    theme: {
      type: String,
      default: "",
    },
    color:{
      type:String,
      default:"#e54035"
    }
  },
  computed: {
    mychart() {
      return echarts.init(this.$refs.sortChart);
    },
    rankKind(){
      if(this.option.series[0].encode.y=='total')
        return '总数'
      else
        return '合格率'
    }
    ,
    myData(){
      let mydata=[]
      let col=this.data.rankCol
      let data=this.data.rankData
      if(col.length!==data.length)
      console.log(new Error("dif length bt rank item & data"))
      for(let i in data)
      {
        let rate=0
        let total=data[i]['qualified']+data[i]['unqualified']
        if(total!==0)
        rate= Math.round(10000*data[i]['qualified']/total)/100
        mydata.push([
          col[i],
          total,
          rate
          ])
      }
      return mydata
    }
  },
  watch: {
    data: function () {
      this.prepare();
    },
  },
  methods: {
    reSizeChart: function () {
      this.mychart.resize();
    },
    prepare() {
      let mydata=this.myData
      mydata.sort((a,b)=>{
      return b[1]-a[1]
     })
      if (mydata) this.option.dataset[0].source = mydata
      else console.log(new Error("Error in data"))
      this.mychart && this.mychart.setOption(this.option);
      this.mychart.getZr().on('click',(e)=>{
        if(e.target)
        return
      let cur=this.option.series[0].encode.y

      if(cur==='total'){
        this.option.series[0].encode.y='rate'
        let data=this.option.dataset[0].source
        data.sort((a,b)=>{
      return b[2]-a[2]
     })
      }else{
        this.option.series[0].encode.y='total'
        this.option.dataset[0].source.sort((a,b)=>{
      return b[1]-a[1]
     })
      }
      this.mychart.setOption(this.option)
      })
    },

  },
  mounted() {
    const resizeObserver = new ResizeObserver(this.reSizeChart);
    resizeObserver.observe(this.$el);
  },
};
</script>

<style lang="less" scoped>
.conpontent-wrapper {
  
  width: 50vh;
  height: 78vh;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--el-box-shadow-base);
  #title {
    width: 100%;
    flex: 0 0 auto;padding:1vh 0;
  }
  #chart {
    width: 100%;
    flex: 1 1 0;
  }
}
#sort-chart {
  color: white;
  // background-color: white;
}
</style>